exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-strapi-personal-slug-jsx": () => import("./../../../src/pages/about/{StrapiPersonal.slug}.jsx" /* webpackChunkName: "component---src-pages-about-strapi-personal-slug-jsx" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-strapi-article-slug-jsx": () => import("./../../../src/pages/blog/{StrapiArticle.slug}.jsx" /* webpackChunkName: "component---src-pages-blog-strapi-article-slug-jsx" */),
  "component---src-pages-category-strapi-category-slug-jsx": () => import("./../../../src/pages/category/{StrapiCategory.slug}.jsx" /* webpackChunkName: "component---src-pages-category-strapi-category-slug-jsx" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-docs-strapi-doc-slug-jsx": () => import("./../../../src/pages/docs/{StrapiDoc.slug}.jsx" /* webpackChunkName: "component---src-pages-docs-strapi-doc-slug-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-price-js": () => import("./../../../src/pages/price.js" /* webpackChunkName: "component---src-pages-price-js" */),
  "component---src-pages-promo-js": () => import("./../../../src/pages/promo.js" /* webpackChunkName: "component---src-pages-promo-js" */),
  "component---src-pages-promo-strapi-promo-slug-jsx": () => import("./../../../src/pages/promo/{StrapiPromo.slug}.jsx" /* webpackChunkName: "component---src-pages-promo-strapi-promo-slug-jsx" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-strapi-service-slug-jsx": () => import("./../../../src/pages/services/{StrapiService.slug}.jsx" /* webpackChunkName: "component---src-pages-services-strapi-service-slug-jsx" */),
  "component---src-pages-test-jsx": () => import("./../../../src/pages/test.jsx" /* webpackChunkName: "component---src-pages-test-jsx" */)
}

